<template>
  <main>
    <slot name="screen"></slot>
  </main>
</template>

<style lang="scss" scoped>
main {
  text-align: center;
  padding: 1rem 2rem;

  &>* {
    text-align: left;
  }
}
</style>