<template>
  <Layout>
    <template v-slot:menuItems>
      <li>
        <router-link to="/contact">KONTAKT</router-link>
      </li>
      <li>
        <router-link to="/about">ÜBER UNS</router-link>
      </li>
      <li>
        <router-link to="/expertise">EXPERTISE</router-link>
      </li>
      <li>
        <router-link to="/references">REFERENZEN</router-link>
      </li>
      <li>
        <router-link to="/services">DIENSTLEISTUNGEN</router-link>
      </li>
    </template>
    <template v-slot:externalLinks>
      <a href="https://www.xing.com/profile/ChristophLukas_Janik/cv" target="_blank">
        <fa icon="xing" type="fab"></fa>
      </a>
      <a href="https://twitter.com/ChristophJanik" target="_blank">
        <fa icon="twitter" type="fab"></fa>
      </a>
      <a href="https://www.facebook.com/CJC-Consult-GmbH-652681631584191" target="_blank">
        <fa icon="facebook" type="fab"></fa>
      </a>
      <a href="https://www.aedin.com/in/christoph-janik/" target="_blank">
        <fa icon="linkedin" type="fab"></fa>
      </a>
    </template>
    <template v-slot:screen>
      <router-view/>
    </template>
  </Layout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Layout from "./components/Layout.vue";
import Card from "./components/Card.vue";
import ContactForm from "./components/ContactForm.vue";

@Options({
  components: {
    Layout,
    ContactForm,
    Card,
  },
})
export default class App extends Vue {
  
}
</script>

<style>
@font-face {
  font-family: "Quicksand";
  src: url("assets/Quicksand-Light.woff2") format("woff2"),
    url("/Quicksand-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

html,
body {
  min-height: 100%;
  height: 100%;

  min-width: 100%;
  width: 100%;
  font-size: 14px;
}

body,
ul,
h1,
h2,
h3,
p,
button,
a,
div {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: "Quicksand";
  letter-spacing: 2px;
  list-style-type: none;
  text-decoration: none;
}
body {
  background: #353535;
  background-image: url("assets/bg.png");
}

body > div {
  background: radial-gradient(circle, #45454500 0%, #25252580 100%);
  min-height: 100%;
}

footer {
  font-size: 0.8rem;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
  border-top-left-radius: 0.5rem;
  opacity: 0.5;
}

footer:hover {
  opacity: 1;
}

.router-link-exact-active {
  color: #f8e112;
  border-bottom: solid 3px #f8e112;
}

@media only screen and (max-width: 480px) {
  footer {
    font-size: 0.6rem;
    text-align: right;
    padding: 0.2rem !important;
  }
}

.container {
  padding-bottom: 50px;
}

.center-screen {
  max-width: 768px;
  height: 100%;
  text-align: center;
  padding: 0 1rem;
  margin: 0 auto;
}

@media only screen and (max-width: 480px) {
  .center-screen {
    padding: 0 !important;
  }
}
</style>
