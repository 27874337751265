<template>
  <Card>
    <div class="container">
        <div class="card">
          <h1>IMPRESSUM UND DATENSCHUTZ</h1>

          <h2>UNTERNEHMENSINFORMATIONEN</h2>

          <div class="label">
            <label>Firma</label>
            <div>CJC CONSULT GmbH</div>
          </div>

          <div class="label">
            <label>UID / UID-Register</label>
            <div>CHE‑387.175.092</div>
          </div>

          <div class="label">
            <label>Geschäftsführer</label>
            <div>Christoph Lukas Janik</div>
          </div>

          <div class="label">
            <label>Adresse</label>
            <div>
              <ul>
                <li>Neunbrunnenstr. 49</li>
                <li>CH-8050 Zürich</li>
              </ul>
            </div>
          </div>
          <div class="label">
            <label>Kontakt</label>
            <div>
              <ul>
                <li>M +49 174 24 87 123</li>
                <li>F +49 322 29 96 05 14</li>
                <li>E christoph.janik@cjc-consult.com</li>
              </ul>
            </div>
          </div>

          <div class="label">
            <label>Inhaltlich Verantwortlicher</label>
            <div>
              <ul>
                <li>Christoph Lukas Janik</li>
                <li>Neunbrunnenstr. 49</li>
                <li>CH-8050 Zürich</li>
              </ul>
            </div>
          </div>

          <section>
            <h2>Datenschutz</h2>
            <p>
              Die CJC freut sich über Ihr Interesse an unserem Unternehmen und
              unserer Website. Wir nehmen den Datenschutz und damit die Wahrung
              Ihrer Persönlichkeitsrechte sehr ernst. Sie erhalten hier
              Informationen über unsere Sicherungsmaßnahmen und
              Datenschutzgrundsätze.
            </p>
          </section>

          <section>
            <h2>Erhebung und Verarbeitung persönlicher Daten</h2>
            <p>
              In der Regel können Sie die Seiten der CJC besuchen, ohne dass wir
              persönliche Daten von Ihnen benötigen.
            </p>
          </section>

          <section>
            <h2>Nutzungsdaten und IP-Protokollierung</h2>
            <p>
              Bei Nutzungsdaten handelt es sich um Daten, die Sie nicht aktiv
              zur Verfügung stellen, sondern die passiv erhoben werden können,
              während Sie sich z. B. auf einer Website bewegen.
            </p>
            <p>
              Beim Besuch unseres Online-Angebots können wir den Domain-Namen
              oder die IP-Adresse des anfragenden Rechners, die Dateianfrage des
              Clients (Dateiname und URL), den HTTP-Antwort-Code, die
              Internetseite, von der Sie uns besuchen, sowie das Datum und die
              Dauer Ihres Besuchs erfassen.
            </p>
            <p>
              CJC wertet Nutzungsdaten anonymisiert zu statistischen Zwecken
              aus. Die Speicherung erfolgt auch zur Ermittlung von Störungen
              oder Missbrauch unseres Online-Angebots und unserer
              Telekommunikationsdienste/-anlagen, soweit sie zum Aufbau weiterer
              Verbindungen erforderlich ist.
            </p>
          </section>
          <section>
            <h2>Cookies</h2>
            <p>
              In einigen Bereichen der Website setzt CJC so genannte Cookies
              ein, um Ihnen unsere Leistung individueller zur Verfügung stellen
              zu können. Cookies sind Kennungen, die ein Webserver an Ihren
              Computer senden kann, um ihn für die Dauer des Besuches zu
              identifizieren. Sie können Ihren Browser so einstellen, dass er
              Sie über die Platzierung von Cookies informiert. So wird der
              Gebrauch von Cookies für Sie transparent.
            </p>
            <p>
              CJC speichert Ihre persönlichen Daten, wie Name und
              E-Mail-Adresse, nur dann, wenn Sie uns diese Daten von sich aus,
              z. B. im Rahmen einer Registrierung oder bei Bestellung einer
              Informationsbroschüre, angeben. In diesem Fall nutzt CJC Ihre
              persönlichen Daten im jeweils dafür erforderlichen Umfang. Falls
              Sie mit einer solchen Nutzung nicht (mehr) einverstanden sein
              sollten, können Sie uns dies jederzeit mitteilen, damit wir die
              Daten entsprechend löschen können. Bitte senden Sie in diesem Fall
              eine E-Mail an die am Ende dieser Datenschutzerklärung genannte
              E-Mail-Adresse oder kontaktieren Sie die dort angegebene
              Postadresse.
            </p>
          </section>
          <section>
            <h2>Nutzung und Weitergabe persönlicher Daten und Zweckbindung</h2>
            <p>
              Im Rahmen einer Registrierung, Kontaktanfrage o. ä. können Sie uns
              Ihre personenbezogenen Daten freiwillig zur Verfügung stellen.
              Ihre personenbezogenen Daten verwenden wir im Allgemeinen, um Ihre
              Anfrage zu beantworten, Ihren Auftrag zu bearbeiten, oder Ihnen
              Zugang zu speziellen Informationen oder Angeboten zu verschaffen.
              Wir werden Ihre Daten nicht an Dritte weitergeben, ausgenommen
              etwaige Dienstleister, die in unserem Auftrag tätig werden und die
              Ihre Daten nur soweit nutzen dürfen, als es für die jeweilige
              Dienstleistung erforderlich ist.
            </p>
          </section>
          <section>
            <h2>Datensicherheit</h2>
            <p>
              Die CJC schützt Ihre Daten durch technische und organisatorische
              Maßnahmen vor unberechtigtem Zugriff, Verlust oder Zerstörung.
              Unsere Sicherheitsmaßnahmen werden entsprechend der
              technologischen Entwicklung fortlaufend verbessert.
            </p>
          </section>
          <section>
            <h2>Auskunft, änderung und Löschung persönlicher Daten</h2>
            <p>
              Sie sind berechtigt, Auskunft zu erhalten über die Daten, die Sie
              CJC auf dieser Website zur Verfügung gestellt haben bzw. die CJC
              über Sie gespeichert hat. Selbstverständlich können Sie falsche
              Daten jederzeit durch uns korrigieren lassen bzw. die Löschung
              gespeicherter Daten verlangen. Bitte senden Sie in diesem Fall
              eine E-Mail an die am Ende dieser Datenschutzhinweise genannte
              E-Mail-Adresse oder kontaktieren Sie die dort angegebene
              Postadresse.
            </p>
          </section>
          <section>
            <h1>Nutzungsbedingungen und rechtliche Hinweise</h1>
            <section>
              <h2>Rechtlicher Hinweis</h2>
              <p>
                Die Inhalte unserer Internetseite werden mit größter Sorgfalt
                recherchiert. Dennoch kann der Anbieter keine Haftung für die
                Richtigkeit, Vollständigkeit und Aktualität der bereit
                gestellten Informationen übernehmen. Die Informationen sind
                insbesondere auch allgemeiner Art und stellen keine Rechts-
                und/oder anderweitige Beratung im Einzelfall dar. Zur Lösung von
                konkreten Anfragen konsultieren Sie uns bitte unbedingt im
                Vorfeld. Die Benutzung erfolgt ausschließlich auf eigenes
                Risiko.
              </p>
            </section>
            <section>
              <h2>Links</h2>
              <p>
                Für Webseiten, auf die wir per Link verweisen, sind
                ausschließlich die jeweiligen Autoren der Seiten verantwortlich.
                CJC lehnt jede Verantwortung bzgl. der Richtigkeit, Aktualität
                und Rechtmäßigkeit externer Informationsangebote ab
                (Distanzierung vom Angebot). Die Verantwortung für die Nutzung
                der Inhalte liegt beim Anwender. Ergänzungsvorschläge oder
                kritische Hinweise zu unserer Linkliste nehmen wir gerne per
                E-Mail info@christoph-janik.com entgegen.
              </p>
            </section>
            <section>
              <h2>Copyright</h2>
              <p>
                Alle Texte, Grafiken, Bilder, Begriffe und das Webdesign sind
                urheberrechtlich geschützt, sofern nicht ausdrücklich anders
                bezeichnet. Rechte Dritter bleiben hiervon unberührt. Bei
                Zeichen oder Namen Dritter liegen die Rechte beim jeweilig
                Berechtigten. Verstöße gegen unser Urheber- und Markenrecht
                werden zivil- und strafrechtlich verfolgt. Für den Inhalt dieser
                Website wird keine Haftung gleich welcher Art übernommen.
                Verwertungen, insb. Vervielfältigungen, dieser Site oder Teile
                der Site bedürfen unserer schriftlichen Genehmigung.
              </p>
            </section>
          </section>
        </div>
      </div>
  </Card>
</template>

<script>
import Card from "../components/Card.vue";

export default {
  components: {
    Card
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #252525;
  text-align: left;
  margin-bottom: 1rem;
  background-color: #d7d7d7;
  border-radius: 4px;
  padding: 0.5rem;
}

.description {
  text-align: left;
  color: #252525;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
}

h1 {
  margin-bottom: 1.2rem;
}

h2 {
  margin-bottom: 0.4rem;
}

h3 {
  margin-bottom: 1rem;
}

h4 {
  margin-bottom: 1rem;
}

h5 {
  margin-bottom: 1rem;
}

h6 {
  margin-bottom: 1rem;
}

.label {
  margin-bottom: 1rem;
}

section {
  padding-bottom: 1.2rem;
}

p {
  padding-bottom: 1rem;
}

.label::after {
  content: " ";
  display: block;
  clear: both;
}

.label > label {
  float: left;
  width: 250px;
  display: block;
  font-weight: 700;
}

.label > div {
  display: inline-block;
}

</style>