<template>
  <Card>
    <h1 class="title">ÜBER CJC CONSULT GmbH</h1>
    <p class="description">
      Die Firma CJC-Consult GmbH mit Sitz in Zürich ist aus der
      Einzelunternehmung CJC - Christoph Janik Consult mit Sitz in Düsseldorf
      entstanden. Die Christoph Janik Consult wurde im März 2012 in Düsseldorf
      gegründet und im Oktober 2016 in die Schweiz überführt. Kerngeschäft der
      CJC ist die Software Architektur, Software Entwicklung/Engineering und
      IT-Beratung sowie der Vertrieb und Lizenzierung der Armata Plattform. Im
      Zeitalter der immer schneller werdenden technologischen Entwicklungen im
      Software/IT Bereich unterstützt die CJC Ihre Kunden im gesamten
      Softwareentwicklungsprozess. Aufgrund des großen Technologie Know Hows der
      CJC im Bereich Web, Client/Server, Mobile (Android, iOS, Windows Phone),
      Desktop (Windows, Linux, Mac) können wir unseren Kunden eine Full Stack
      Lösung anbieten. Stetig ändernde Marktanforderungen wie beispielsweise
      technologische Innovationen oder ändernde regulatorische Bedingungen
      stellen für Unternehmen große Herausforderungen dar. Mit unseren
      Dienstleistungen helfen wir Unternehmen Ihre Geschäftsprozesse,
      Technologien und Innovationen zielgerichtet zu optimieren, mit denen sie
      Ihre Effizienz dauerhaft verbessern können und gleichzeitig ein
      nachhaltigen Mehrwert für Ihre Unternehmung, Stakeholder und Kunden
      erreichen.
    </p>
  </Card>
</template>

<script>
import Card from "../components/Card.vue";

export default {
  components: {
    Card
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #252525;
  text-align: left;
  margin-bottom: 1rem;
  background-color: #d7d7d7;
  border-radius: 4px;
  padding: 0.5rem;
}

.description {
  text-align: left;
  color: #252525;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
}
</style>