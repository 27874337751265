<template>
  <div class="card">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.card {
  border-radius: 0.6rem;
  max-width: calc(100% - 8rem);
  display: inline-block;
  position: relative;
  padding: 1rem;
  max-width: calc(100% - 2rem);
  margin: auto;
  background-color: #eee;
  color: #252525;
  -ms-box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.8);
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.8);

  &:before {
    position: absolute;
    content: " ";
    top: -16px;
    left: -16px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 16px solid #454545;
    opacity: 0.6;
    border-radius: 25px;
    -ms-box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.8);
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.8);
  }
}

@media only screen and (max-width: 992px) {
  .card {
    margin: 1.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .card {
    padding: 0.4rem !important;
    max-width: calc(100% - 1rem) !important;
  }

  .card:before {
    top: -8px !important;
    left: -8px !important;
    border: 8px solid #454545 !important;
  }
}
</style>