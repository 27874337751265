<template>
  <div class="landing-menu">
    <h3>CJC CONSULT - SOFTWARE ENGINEERING</h3>
    <ul>
      <li>
        <router-link to="/contact">KONTAKT</router-link>
      </li>
      <li>
        <router-link to="/about">ÜBER UNS</router-link>
      </li>
      <li>
        <router-link to="/expertise">EXPERTISE</router-link>
      </li>
      <li>
        <router-link to="/references">REFERENZEN</router-link>
      </li>
      <li>
        <router-link to="/services">DIENSTLEISTUNGEN</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style>
.landing-menu {
  max-width: 300px;
  margin: 100px auto 40px;
  color: #fff;
}

@media only screen and (max-width: 480px) {
  .landing-menu {
    max-width: 300px;
    margin: 1rem auto 40px !important;
  }

  .landing-menu > ul {
    max-width: 200px;
    margin: auto;
  }
}
.landing-menu h3 {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.landing-menu li {
  padding: 10px;
  cursor: pointer;
}
</style>