<template>
  <footer class="footer">
    Copyright 2021 CJC CONSULT GmbH | <router-link to="/impressum">IMPRESSUM</router-link>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  color: #fff;
  padding: 0 3rem 0.5rem 1rem;
  background-color: #000000ff;

  & > a:hover {
    cursor: pointer;
    color: #f8e112;
  }
}
</style>