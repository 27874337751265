<template>
  <nav>
    <div class="logo">
      <img src="../assets/logo.png" />
    </div>
    <div class="mainmenu">
      <div class="mainmenuContent">
        <div>
          <div class="pagetitle">
            <h1>
              <router-link to="/">CJC CONSULT</router-link>
            </h1>
          </div>
          <div class="menu">
            <ul :class="{ showMenu: showMenu }">
              <slot name="menuItems"></slot>
            </ul>
          </div>
        </div>
      </div>
      <div class="mainmenuSubContent">
        <div>
          <div class="description">
            SOFTWARE ENGINEERING | SOFTWARE ARCHITEKTUR
          </div>
          <div class="externalPages">
            <slot name="externalLinks"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="mainmenuBtn">
      <button @click="toggleMenu">
        <fa icon="bars" type="fa" v-if="!showMenu"></fa>
        <fa icon="times" type="fa" v-if="showMenu"></fa>
      </button>
    </div>
  </nav>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Navbar extends Vue {
  showMenu = false;

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
</script>

<style lang="scss">
.mainmenuBtn {
	padding: 0.5rem 1rem;
	width: 1rem;
	vertical-align: middle;
	display: table-cell;
	>button {
		background-color: transparent;
		border: 0 solid transparent;
		cursor: pointer;
		width: 42px;
		height: 42px;
		>svg {
			width: 1rem;
			height: 1rem;
		}
		&:active {
			outline: 0;
		}
		&:focus {
			outline: 0;
		}
	}
}
nav {
  color: white;
	position: relative;
	background-color: #00000080;
	box-sizing: border-box;
	display: table;
	width: 100%;
	margin-bottom: 1.8rem;
	box-shadow: 0px 0px 10px 0px #000;
	>.logo {
		display: table-cell;
		width: 64px;
		height: 48px;
		vertical-align: middle;
		padding: 0.5rem;
		>img {
			width: 48px;
			height: 48px;
		}
	}
	>.mainmenu {
		display: table-cell;
		vertical-align: middle;
	}
}

.mainmenuContent {
	display: table;
	height: 33px;
	width: 100%;
	>div {
		display: table-row;
		>div {
			display: table-cell;
			padding-bottom: 0.2rem;
			border-bottom: 1px solid #fff;
			vertical-align: bottom;
			>h1 {
				white-space: nowrap;
			}
		}
	}
}
.mainmenuSubContent {
	display: table;
	height: 33px;
	width: 100%;
	>div {
		display: table-row;
		font-size: 0.8rem;
		>div {
			display: table-cell;
			padding-bottom: 0.2rem;
		}
	}
}
.menu {
	>ul {
		display: none;
	}
}
.showMenu {
	display: block !important;
	position: absolute;
	right: 0px;
	text-align: right;
	top: 66px;
	z-index: 10;
	background: #252525ee;
	padding: 0.5rem;
	border-top-left-radius: 1rem;
	transform: scaleY(-1);
	>li {
		transform: scaleY(-1);
		padding: 0.5rem 0.5rem 0 0.5rem;
		&:last-child {
			padding-bottom: 0.5rem;
		}
	}
}
.description {
	vertical-align: top;
}
.externalPages {
	display: none;
	vertical-align: middle;
	text-align: right;
	>a {
		padding: 0.5rem;
		margin: 0.5rem;
		cursor: pointer;
		&:hover {
			color: #f8e112;
		}
		>svg {
			width: 1.2rem;
			height: 1.2rem;
			font-size: 1.2rem;
		}
	}
}
@media only screen and (max-width: 480px) {
	.mainmenuSubContent {
		display: block;
		height: 33px;
		width: 100%;
		>div {
			display: block;
			font-size: 0.8rem;
			>div {
				display: block;
				&:first-child {
					display: block;
					padding-top: 0.2rem;
					font-size: 0.7rem;
				}
			}
		}
	}
	.externalPages {
		background-color: #00000080;
		border-top-right-radius: 1rem;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 10;
		>a {
			display: block;
		}
	}
}
@media only screen and (min-width: 955px) {
	.menu {
		text-align: right;
		&>ul {
			display: block;
			&::after {
				content: " ";
				display: block;
				clear: both;
			}
			&>li {
				float: right;
				padding: 0 1rem;
				display: block;
				white-space: nowrap;
				border-right: solid 1px #fff;
				box-sizing: content-box;
				&>a {
					&:hover {
						color: #f8e112;
					}
				}
				&:first-child {
					border-right: none;
				}
			}
		}
	}
	.mainmenuBtn {
		display: none;
	}
}

.pagetitle>h1>a {
  border-bottom: solid 0 transparent !important;
  color: #fff !important;
}

</style>