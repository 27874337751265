<template>
  <Card>
    <h1 class="title">TECHNOLOGIEN</h1>
    <p class="description">
      Hier sehen Sie die Kerntechnologien der CJC CONSULT
    </p>
    <hr />
    <h3 class="subtitle">FRONTEND</h3>
    <div class="backend">
      <div>
        <fa icon="angular" type="fab"></fa>
        <div>
          AngularJS, Angular2 - Angular 10, Angular Material, Protractor, ...
        </div>
      </div>
      <div>
        <fa icon="react" type="fab"></fa>
        <div>React, Nextjs, ...</div>
      </div>
      <div>
        <fa icon="html5" type="fab"></fa>
        <div>HTML, HTML5, JavaScript, jQuery, Dojo Toolkit, ...</div>
      </div>
      <div>
        <fa icon="css3" type="fab"></fa>
        <div>CSS, CSS3, LESS, SASS, Bootstrap, Foundation ...</div>
      </div>
      <div>
        <fa icon="vuejs" type="fab"></fa>
        <div>VueJS, Vuetify, ...</div>
      </div>
      <div>
        <fa icon="font-awesome" type="fab"></fa>
        <div>FontAwesome, ...</div>
      </div>
      <div>
        <fa icon="sass" type="fab"></fa>
        <div>LESS, SASS, ...</div>
      </div>
      <div>
        <fa icon="js" type="fab"></fa>
        <div>Javascript, TypeScript, CoffeeScript, ...</div>
      </div>
      <div>
        <fa icon="code" type="fa"></fa>
        <div>jQuery, Dojo Toolkit, ...</div>
      </div>
    </div>
    <h3 class="subtitle">BACKEND</h3>
    <div class="backend">
      <div>
        <img src="../assets/technologies/golang.png" />
        <div>golang, martini, gorilla mux, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/nodejs.png" />
        <div>
          Node.js, NPM, ExpressJS, typescript, Jest, Webpack, Protractor, ...
        </div>
      </div>
      <div>
        <img src="../assets/technologies/phyton.png" />
        <div>phyton, Django, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/xamarin.png" />
        <div>
          xamarin, Crossplatform, Nativ iOS, OS X, Android Development, ...
        </div>
      </div>
      <div>
        <img src="../assets/technologies/dotNet.png" />
        <div>.Net, Visual Studio, C#, C++, VS.Net, ASP.Net, Hibernate, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/dotNetCore.png" />
        <div>
          .Net Core, .Net 5, Visual Studio, C#, C++, ASP.Net, Hibernate, ...
        </div>
      </div>
      <div>
        <img src="../assets/technologies/javaee.png" />
        <div>
          Java (JavaSE / JavaEE), Spring, Springboot, Entity Framework, ...
        </div>
      </div>
    </div>
    <h3 class="subtitle">DATABASES</h3>
    <div class="backend">
      <div>
        <img src="../assets/technologies/mongodb.png" />
        <div>mongodb, mongoose, atlas, Robo T3, Studio T3 ...</div>
      </div>
      <div>
        <img src="../assets/technologies/oracledb.png" />
        <div>Oracle DB, toad, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/sqlserver.png" />
        <div>MS SQL Server, MS SQL developer tools, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/mysql.png" />
        <div>MySQL Server, MySQL GUI Tools, MySQL Workbench, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/postgresql.png" />
        <div>Postgresql, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/sqLite.png" />
        <div>SQLite, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/redis.png" />
        <div>
          Redis, redis in-memory data structure store, caching server, ...
        </div>
      </div>
    </div>
    <h3 class="subtitle">INFRASTRUKTUR und CLOUD</h3>
    <div class="backend">
      <div>
        <img src="../assets/technologies/azure.png" />
        <div>Azure, Azure devOps, CI/CD, VM, CT, Kubernetes, HA, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/aws.png" />
        <div>AWS, Amazon Cloud, devOps, CI/CD, VM, CT, Kubernetes, HA, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/googlecloud.png" />
        <div>google cloud, devOps, CI/CD, VM, CT, Kubernetes, HA, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/digitalocean.png" />
        <div>digitalocean, devOps, CI/CD, VM, CT, Kubernetes, HA, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/proxmox.png" />
        <div>Proxmox, Virtualisation, High Availability private cloud, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/openstack.png" />
        <div>
          Openstack, Virtualisation, High Availability private cloud ...
        </div>
      </div>
      <div>
        <img src="../assets/technologies/nginx.png" />
        <div>nginx webserver, nginx http proxy, nginx reverse proxy, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/docker.png" />
        <div>docker, docker-compose, swarm, ...</div>
      </div>
      <div>
        <img src="../assets/technologies/kubernetes.png" />
        <div>kubernetes, helm, ...</div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from "../components/Card.vue";

export default {
  components: {
    Card,
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #252525;
  text-align: left;
  margin-bottom: 1rem;
  background-color: #d7d7d7;
  border-radius: 4px;
  padding: 0.5rem;
}

.description {
  text-align: left;
  color: #252525;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
}

.subtitle {
  text-align: left;
  padding: 0.5rem;
}

.description {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
  margin-bottom: 1rem;
}

.subdescription {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin: 0;
}

.technology {
  position: relative;
}

.technology::after {
  content: " ";
  display: block;
  clear: both;
}

.technology > ul {
  float: left;
  width: calc(50% - 2rem);
  margin: auto;
  padding: 0.5rem;
}

.technology > ul > li {
  cursor: pointer;
  text-align: left;
}

.technology > ul > li > div {
  width: 50%;
}

.technology > ul > li > div {
  padding-left: 50%;
}

.technology > ul:last-child > li > div {
  padding-left: 25%;
}

.technology > ul > li > div > div {
  position: relative;
  display: inline-block;
  color: inherit;
  top: -8px;
}

.technology > ul > li > div > svg {
  width: 32px !important;
  height: 32px;
  margin-right: 0.5rem;
}

.technology > ul > li > div:hover {
  color: #f8e112;
}

.backend > div {
  padding: 0.5rem;
  background-color: #fff;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  color: #000;
}

.backend > div::after {
  content: " ";
  display: block;
  clear: both;
}

.backend > div > img {
  width: 100px;
  float: left;
}

.backend > div > svg {
  width: 32px !important;
  height: 32px;
  float: left;
}

.backend > div > div {
  padding: 0.5rem;
  float: left;
  color: #000;
}
</style>