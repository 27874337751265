
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Navbar extends Vue {
  showMenu = false;

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
