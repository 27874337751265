
import { Options, Vue } from "vue-class-component";

import Layout from "./components/Layout.vue";
import Card from "./components/Card.vue";
import ContactForm from "./components/ContactForm.vue";

@Options({
  components: {
    Layout,
    ContactForm,
    Card,
  },
})
export default class App extends Vue {
  
}
