<template>
  <Card>
    <h1 class="title">REFERENZEN</h1>
    <h4 class="h4">
      Hier finden Sie eine Auflistung unserer bisherigen Projekte.
    </h4>
    <p class="description">
      Kontaktieren Sie uns unter
      <a href="tel:+491742487123">+49 174 24 87 123</a> oder per Mail
      <a href="mailto:info@cjc-consult.com">info@cjc-consult.com</a>.
    </p>
    <div class="references">
      <h3 class="subtitle">
        Entwicklung einer online Plattform für einen Energieversorger
      </h3>
      <h4>03.2018 - 12.2019 und 03.2020 - 12.2020</h4>
      <h6>EINSATZORT:</h6>
      Landquart, Switzerland
      <h6>PROJEKTROLLE:</h6>
      Full-Stack Developer, DevOps, Lead Frontend Developer
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung eines Online Portals sowie einer Android und einer iOS App für
      einen Energieversorger.
      <h6>TECHNOLOGIEN:</h6>
      Node.js, Angular 4 - Angular 10, Angular-cli, git, C#, ASP.Net, .Net Core
      2.0, Typescript, Continuous Integration, Continuous Delivery, DevOps
    </div>

    <div class="references">
      <h3 class="subtitle">
        Entwicklung eines Jump&Run Spiels für einen Energieversorger
      </h3>
      <h4>09.2018 - 04.2019</h4>
      <h6>EINSATZORT:</h6>
      München, Germany (Remote und vor Ort)
      <h6>PROJEKTROLLE:</h6>
      Unity 3D Developer
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung eines Spiels für Android und iOS für einen Energieversorger im
      zuge der Gamification Strategie des Kunden.
      <h6>TECHNOLOGIEN:</h6>
      C# Unity 3D, Continuous Integration, Continuous Delivery
    </div>

    <div class="references">
      <h3 class="subtitle">
        Entwicklung einer Human Resources Software
      </h3>
      <h4>07.2017 - 12.2017</h4>
      <h6>EINSATZORT:</h6>
      Winterthur, Switzerland
      <h6>PROJEKTROLLE:</h6>
      Full-Stack Developer, DevOps
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung eines Online Portals für Human Resources Management.
      <h6>TECHNOLOGIEN:</h6>
      Node.js, Angular 4, Angular-cli, git, Intelij, Java, Jooq, PostgrSQL,
      Typescript, Continuous Integration, Continuous Delivery, DevOps
    </div>

    <div class="references">
      <h3 class="subtitle">
        Entwicklung eines Angular Frameworks
      </h3>
      <h4>03.2017 - 03.2019</h4>
      <h6>EINSATZORT:</h6>
      Zürich, Switzerland
      <h6>PROJEKTROLLE:</h6>
      Full-Stack Developer, DevOps
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung eines Stacks auf Basis von node.js, Expess.js, Angular 4,
      SASS, docker... Bei diesem Stack wurde auf extrem hohe Skalierbarkeit,
      Maintenance und Automatisierung Wert gelegt. Der die gesamte Frontend
      Bereich dieses Stacks kann vom Backend aus verändert werden.
      <h6>TECHNOLOGIEN:</h6>
      Node.js express.js, nginx, Angular, Angular-cli, git, visual studio code,
      Typescript, Continuous Integration, Continuous Delivery, DevOps
    </div>

    <div class="references">
      <h3 class="subtitle">
        Entwicklung und Optimierung eines Internetportals für die
        Versicherungsbranche
      </h3>
      <h4>04.2015 - 03.2017</h4>
      <h6>EINSATZORT:</h6>
      Zürich, Switzerland
      <h6>PROJEKTROLLE:</h6>
      Software Developer Frontend/GUI.
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Einrichten einer einheitlichen Node.js, GulpJS, AngularJS
      Entwicklungsbasis. Build Management (GulpJS, Maven). Release
      Management(SVN, GIT). Lead Entwicklung (Architektur und Programmierung)
      mehrerer AngularJS App ́s zum kalkulieren einer Haushalts /
      Privathaftpflichtversicherung, Rechtsschutzversicherung, Autoversicherung,
      Motorradversicherung, Reiseversicherung sowie Elektronischer
      Versicherungsnachweis.
      <br />
      <br />
      <a href="https://tarif.allianz.ch/asu_cdn/apps/asu_mokhh-gui/#/de/">
        Privathaftpflichtversicherung
      </a>
      <a href="https://tarif.allianz.ch/asu_cdn/apps/asu_mocap-gui/#/de/">
        Rechtschutzversicherung
      </a>
      <a href="https://tarif.allianz.ch/asu_cdn/apps/asu_momf-gui/#/de/">
        Fahrzeugversicherung
      </a>
      <a href="https://tarif.allianz.ch/asu_cdn/apps/asu_momr-gui/#/de/">
        Motorradversicherung
      </a>
      <a href="https://tarif.allianz.ch/asu_cdn/apps/asu_mors-gui/#/de/">
        Reiseversicherung
      </a>
      <h6>TECHNOLOGIEN:</h6>
      NodeJS, AngularJS, git, svn, Eclipse, sublimeText2, GulpJS, Java, JSF,
      JSP, Protractor, AngularJS2, Typescript, Continuous Integration,
      Continuous Delivery
    </div>

    <div class="references">
      <h3 class="subtitle">
        iOS, Android und Windows 10 App Entwicklung
      </h3>
      <h4>08.2016 - 12.2016</h4>
      <h6>EINSATZORT:</h6>
      Überwiegend Remote und teils in Ratingen, Germany
      <h6>PROJEKTROLLE:</h6>
      Softwarearchitekt, Softwareentwickler
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Weiterentwicklung einer Plattformunabhängigen Applikation.
      <h6>TECHNOLOGIEN:</h6>
      visual studio code, Node.js, cordova, Angular JS, Git, Objective C,
      Android SDK, .NET 4.5, Xamarin
    </div>

    <div class="references">
      <h3 class="subtitle">
        Entwicklung einer Windows Applikation zur Anzeige und Kalkulation vom
        Produktportfolio des Kunden.
      </h3>
      <h4>10.2014 - 04.2015</h4>
      <h6>EINSATZORT:</h6>
      Überwiegend Remote und teils in Ratingen, Germany
      <h6>PROJEKTROLLE:</h6>
      Softwarearchitekt, Softwareentwickler.
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung einer Windows Applikation zur Anzeige und Kalkulation vom
      Produktportfolio des Kunden.
      <h6>TECHNOLOGIEN:</h6>
      C#, Visual Studio 2010, git, gimp
    </div>

    <div class="references">
      <h3 class="subtitle">
        Entwicklung und Optimierung eines Customer Self Service - Portals für
        den Versicherungsmarkt
      </h3>
      <h4>11.2014 - 03.2015</h4>
      <h6>EINSATZORT:</h6>
      Zürich, Switzerland
      <h6>PROJEKTROLLE:</h6>
      Softwarearchitekt, Softwareentwickler
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung und Optimierung eines Customer Self Service - Portals für den
      Versicherungsmarkt. Buildmanagement
      <h6>TECHNOLOGIEN:</h6>
      AngularJS, HTML5, Node.js, CSS3, JavaScript, Subversion, tortoisesvn,
      SublimeText, Eclipse, Java, JavaServer Faces JSF, JavaServer Pages JSP,
      Lotus Notes, JSP, JSF, Protractor, Selenium, KarmaJS, Jasmin.js, Maven
    </div>

    <div class="references">
      <h3 class="subtitle">Eigenes Projekt</h3>
      <h4>10.2014 - 03.2015</h4>
      <h6>EINSATZORT:</h6>
      Hilden, Germany
      <h6>PROJEKTROLLE:</h6>
      Softwarearchitekt, Softwareentwickler
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung einer Web Plattform
      <h6>TECHNOLOGIEN:</h6>
      HTML5, CSS3, Node.js, AngularJS, JavaScript, Git, Sublime Text 2,
      OpenStack, Ubuntu, OS X, Windows, Xamarin Studio, iOS, Android, Windows
      Phone
    </div>

    <div class="references">
      <h3 class="subtitle">Entwicklung einer Android App</h3>
      <h4>10.2014 - 12.2014</h4>
      <h6>EINSATZORT:</h6>
      Ratingen, Germany
      <h6>PROJEKTROLLE:</h6>
      Softwarearchitekt, Softwareentwickler
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung einer Android App zur Anzeige und Kalkulation vom
      Produktportfolio des Kunden
      <h6>TECHNOLOGIEN:</h6>
      Cordova, Java, HTML5, CSS3, AngularJS, JavaScript, Git, Sublime Text 2
    </div>

    <div class="references">
      <h3 class="subtitle">Entwicklung einer iOS App</h3>
      <h4>09.2014 - 10.2014</h4>
      <h6>EINSATZORT:</h6>
      Ratingen, Germany
      <h6>PROJEKTROLLE:</h6>
      Softwarearchitekt, Softwareentwickler
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Entwicklung einer iOS App zur Anzeige und Kalkulation vom Produktportfolio
      des Kunden
      <h6>TECHNOLOGIEN:</h6>
      Cordova, Objective-C, HTML5, CSS3, AngularJS, JavaScript, Git, Sublime
      Text 2
    </div>

    <div class="references">
      <h3 class="subtitle">
        Weiterentwicklung eines Hotelbewertung Portales
      </h3>
      <h4>05.2014 - 08.2014</h4>
      <h6>EINSATZORT:</h6>
      Bottighofen, Switzerland
      <h6>PROJEKTROLLE:</h6>
      Softwareentwickler, Frontend- Entwicklung
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Weiterentwicklung eines Hotel-/Reisebewertungs-Portals
      <h6>TECHNOLOGIEN:</h6>
      Node.js, HTML5, JavaScript, tready, BackboneJS, jQuery, JavaScript,
      CoffeeScript, SASS, Handlebars.js, MongoDB, nginx, git, Sublime Text 2,
      Jasmin.js, Karma.js, Gulp.js
    </div>

    <div class="references">
      <h3 class="subtitle">
        Weiterentwicklung der Cloud Application E|C|A - energy|cloud|application
      </h3>
      <h4>10.2012 - 04.2014</h4>
      <h6>EINSATZORT:</h6>
      Düsseldorf, Germany
      <h6>PROJEKTROLLE:</h6>
      Geschäftsführer, Leiter Softwareentwicklung, Softwareentwickler
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Weiterentwicklung der Cloud Application E|C|A - energy|cloud|application
      <h6>TECHNOLOGIEN:</h6>
      C#, HTML5, Bootstrap, AngularJS, jQuery, Dojo Toolkit, JavaScript,
      Node.js, MongoDB, Phonegap, Cordova, OpenStack, Proxmox, nginx, Xamarin
      Studio, Git
    </div>

    <div class="references">
      <h3 class="subtitle">
        Datenbereinigung zwischen den Systemen SOPTIM VIM, SCHLEUPEN und Klafka
        & Hinz firstNet und Erstellung einer Software zur Datenanalyse
      </h3>
      <h4>05.2012 - 08.2013</h4>
      <h6>EINSATZORT:</h6>
      Deutschlandweit
      <h6>PROJEKTROLLE:</h6>
      Softwareentwickler, Senior IT Business - Consultant
      <h6>PROJEKTBESCHREIBUNG:</h6>
      Datenbereinigung zwischen den Systemen SOPTIM VIM, SCHLEUPEN und Klafka &
      Hinz firstNet. Erstellung einer Software zur Datenanalyse
      <h6>TECHNOLOGIEN:</h6>
      C#, HTML5, bootstrap, jquery, JavaScript, NodeJS, mongodb, OpenStack,
      proxmox, nginx, xamarin studio, Git, MS Office, SOPTIM VIM, SCHLEUPEN,
      Klafka & Hinz firstNet
    </div>
  </Card>
</template>

<script>
import Card from "../components/Card.vue";

export default {
  components: {
    Card,
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #252525;
  text-align: left;
  margin-bottom: 1rem;
  background-color: #d7d7d7;
  border-radius: 4px;
  padding: 0.5rem;
}

.description {
  text-align: left;
  color: #252525;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
}

.h4 {
  padding: 0.5rem;
  text-align: left;
}

.subtitle {
  text-align: left;
  padding: 0.5rem;
}

.description {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
  margin-bottom: 1rem;
}

.description > a {
  text-decoration: underline;
}

.subdescription {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin: 0;
}

.references {
  text-align: left;
  padding: 1rem;
  background-color: #fff;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
}

.references > a {
  color: #000086;
  display: block;
  margin: 0.2rem 0;
  text-decoration: underline;
}

.references > h3 {
  color: #000;
  padding: 1rem;
  margin: -1rem -1rem 1rem -1rem;
  background-color: #45454545;
}

.references > h6 {
  margin-block-end: 0.4rem;
}
</style>