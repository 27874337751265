<template>
  <Card>
    <h1 class="title">UNSERE DIENSTLEISTUNGEN</h1>
    <p class="description">
      Als Softwareunternehmen haben wir es uns zur Aufgabe gemacht, gemeinsam
      mit unseren Kunden die innovativsten Softwarelösungen umzusetzen. Dabei
      achten wir besonders auf eine passgenaue und zukunftssichere sowie
      kostengünstige Softwarelösung. Von der Planung der Software Architektur
      oder IT-Strategie bis hin zur Programmierung der Software mit
      anschließendem Tests und inbetriebnahme bieten wir eine komplette SDLC
      vorgehensweise an.
    </p>
    <hr />
    <h3 class="subtitle">ZUSAMMENARBEIT</h3>
    <p class="subdescription">
      Eine erfolgreiche Zusammenarbeit basiert auf gegenseitigem Vertrauen. Wir
      legen daher sehr viel Wert auf eine faire und partnerschaftliche
      Zusammenarbeit mit unseren Kunden. Um höchste Zufriedenheit bei unseren
      Kunden zu erreichen und gleichzeitig Konfliktpotenziale zu vermeiden,
      arbeiten wir stets verantwortungsbewusst und transparent. Mit modernsten
      Trackingtools dokumentieren wir sämtliche anfallenden Tasks, sodass der
      Projektfortschritt und die Kosten jederzeit nachvollziehbar sind. Diese
      Vorgehensweise sichert eine gute transparente und partnerschaftliche
      Zusammenarbeit die unsere hohen Ansprüche erfüllt.
    </p>
  </Card>
</template>

<script>
import Card from "../components/Card.vue";

export default {
  components: {
    Card,
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #252525;
  text-align: left;
  margin-bottom: 1rem;
  background-color: #d7d7d7;
  border-radius: 4px;
  padding: 0.5rem;
}

.description {
  text-align: left;
  color: #252525;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
}

.subtitle {
  text-align: left;
  padding: 0.5rem;
}

.description {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
  margin-bottom: 1rem;
}

.subdescription {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: .5rem 0.5rem 0 0.5rem;
  margin: 0;
}

</style>