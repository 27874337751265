import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faCode, faEnvelopeOpen, faGlobe, faPhone, faRoad, fas, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faXing, faLinkedin, faFacebook, faAngular, faReact, faHtml5, faCss3, faVuejs, faFontAwesome, faSass, faJs } from "@fortawesome/free-brands-svg-icons";
import FontAwesomeIcon from "@/libs/FontAwesomeIcon.vue";

library.add(
  fas,
  faTwitter,
  faXing,
  faLinkedin,
  faFacebook,
  faBars,
  faTimes,
  faRoad,
  faGlobe,
  faEnvelopeOpen,
  faPhone,
  faAngular,
  faReact,
  faHtml5,
  faCss3,
  faVuejs,
  faFontAwesome,
  faSass,
  faJs,
  faCode
  );

export { FontAwesomeIcon };