
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Landing from '../pages/Landing.vue';
import About from '../pages/About.vue';
import Contact from '../pages/Contact.vue';
import Expertise from '../pages/Expertise.vue';
import Impressum from '../pages/Impressum.vue';
import References from '../pages/References.vue';
import Services from '../pages/Services.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      title: 'CJC CONSULT GmbH',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  }, {
    path: '/about',
    name: 'About',
    component: About
  }, {
    path: '/contact',
    name: 'Contact',
    component: Contact
  }, {
    path: '/expertise',
    name: 'Expertise',
    component: Expertise
  }, {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  }, {
    path: '/references',
    name: 'References',
    component: References
  }, {
    path: '/services',
    name: 'Services',
    component: Services
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => ((el && el.parentNode) ? el.parentNode.removeChild(el) : null));
  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags.map((tagDef: { [x: string]: string }) => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    .forEach((tag: HTMLElement) => document.head.appendChild(tag));

  next();
});

export default router
