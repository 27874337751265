<template>
  <Card styles="text-align: left;">
    <h1 class="title">KONTAKT</h1>
    <p class="description">Gerne können Sie uns kontaktieren:</p>
    <ul class="contactInfos">
      <li><img src="../assets/logo.png" alt="" /> CJC-Consult GmbH</li>
      <li><fa icon="road" type="fa"></fa> Neunbrunnenstr.49</li>
      <li><fa icon="globe" type="fa"></fa> CH-8050 Zürich, Schweiz</li>
      <li>&nbsp;</li>
      <li>
        <fa icon="phone" type="fa"></fa>
        <a href="tel:+491742487123"> +49 174 24 87 123</a>
      </li>
      <li>
        <fa icon="envelope" type="fa"></fa>
        <a href="mailto:christoph.janik@cjc-consult.com">
          christoph.janik@cjc-consult.com
        </a>
      </li>
    </ul>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2699.75581450211!2d8.536803551552744!3d47.41670387907032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a91dcdff2e9%3A0xcab05d745795f1fe!2sNeunbrunnenstrasse%2049%2C%208050%20Z%C3%BCrich!5e0!3m2!1sde!2sch!4v1612660568118!5m2!1sde!2sch"
      width="100%"
      height="200px"
      frameBorder="0"
      aria-hidden="false"
      tabIndex="{0}"
    ></iframe>
  </Card>
</template>

<script>
import Card from "../components/Card.vue";

export default {
  components: {
    Card,
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #252525;
  text-align: left;
  margin-bottom: 1rem;
  background-color: #d7d7d7;
  border-radius: 4px;
  padding: 0.5rem;
}

.description {
  text-align: left;
  color: #252525;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 1rem 0.5rem 0 0.5rem;
}

.contactInfos {
  padding-top: 1rem;
  margin-bottom: 2rem;
}

.contactInfos > li {
  text-align: left;
}

.contactInfos > li > svg {
  width: 1rem;
  height: 1rem;
}

.contactInfos > li > img {
  width: 1rem;
  text-align: left;
}
</style>