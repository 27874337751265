
import { Options, Vue } from "vue-class-component";

import Navbar from "./Navbar.vue";

@Options({
  components: {
    Navbar,
  },
})
export default class Layout extends Vue {}
