
import { Options, Vue } from "vue-class-component";

import Header from "./Header.vue";
import Screen from "./Screen.vue";
import Footer from "./Footer.vue";

@Options({
  components: {
    Header,
    Screen,
    Footer,
  },
})
export default class Layout extends Vue {}
