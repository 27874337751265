<template>
  <form>
    <div class="form-input">
      <label for="name">Name</label>
      <input type="text" id="name" />
    </div>
    <div class="form-input">
      <label for="email">EMail</label>
      <input type="email" id="email" required v-model="email" />
    </div>
    <div class="form-input">
      <label for="subject">Betreff</label>
      <input type="text" id="subject" />
    </div>
    <div class="form-input">
      <label for="message">Nachricht</label>
      <input type="text" id="message" />
    </div>
    {{email}}
  </form>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      email: ''
    }
  },
  components: {
  },
})
export default class ContactForm extends Vue {}
</script>

<style lang="scss" scoped>
.form-input {

  margin: 0 0 1rem 0;

  &>label {
    display: block;
    font-size: .8rem;
    text-transform: uppercase;
  }

  &>input {
    font-size: 1rem;
    padding: .25rem .5rem;
    background-color: transparent;
    border: solid 1px transparent;
    border-bottom: solid 1px #aaa;
  }
}
</style>