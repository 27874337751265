<template>
  <Navbar>
    <template v-slot:menuItems>
      <slot name="menuItems"></slot>
    </template>
    <template v-slot:externalLinks>
      <slot name="externalLinks"></slot>
    </template>
  </Navbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Navbar from "./Navbar.vue";

@Options({
  components: {
    Navbar,
  },
})
export default class Layout extends Vue {}
</script>