<template>
  <div class="layout">
    <Header>
      <template v-slot:menuItems>
        <slot name="menuItems"></slot>
      </template>
      <template v-slot:externalLinks>
        <slot name="externalLinks"></slot>
      </template>
    </Header>
    <Screen>
      <template v-slot:screen>
        <slot name="screen"></slot>
      </template>
    </Screen>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Header from "./Header.vue";
import Screen from "./Screen.vue";
import Footer from "./Footer.vue";

@Options({
  components: {
    Header,
    Screen,
    Footer,
  },
})
export default class Layout extends Vue {}
</script>